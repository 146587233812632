import React, { useState, useEffect } from "react";
import Login from "./pages/Login/Login";

import AppRoutes from "./config/AppRoutes";
import "./styles/reset.scss";
import "./App.scss";

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("loggedIn") === "true";

    if (isLoggedIn) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogin = (seller) => {
    if (seller) {
      console.log("ok");
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("sellerData", JSON.stringify(seller));
      setLoggedIn(true);
    }
  };

  const handleLogout = () => {
    localStorage.clear();

    window.location.assign("/");
  };

  return (
    <>
      {loggedIn ? (
        <AppRoutes logOut={handleLogout} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </>
  );
}

import React from "react";

import { Routes, Route } from "react-router-dom";

import Template from "../template/Template";
import Home from "../pages/Home/Home";
import Orders from "../pages/Orders/Orders";
import List from "../pages/List/List";
import SuccessPage from "../pages/ResultPage/SuccessPage";
import FeildPage from "../pages/ResultPage/FeildPage";
import ReceiptPage from "../pages/ResultPage/ReceiptPage";

function AppRoutes({ logOut }) {
  return (
    <Routes>
      <Route path="/" element={<Template logOut={logOut} />}>
        <Route index element={<Home />} />
        <Route path="orders" element={<Orders />} />
        <Route path="list" element={<List />} />
        <Route path="paymentSuccess" element={<SuccessPage />} />
        <Route path="paymentFailed" element={<FeildPage />} />
        <Route path="receipt" element={<ReceiptPage />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;

import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import axios from "axios";
import { useFormik } from "formik";
import { loginSchema } from "../../schemas";
import "./login.scss";

import LoginSlider from "../../components/LoginSlider/LoginSlider";
import Logo from "../../assets/img/total-primary.svg";

import { User, Lock } from "../../components/Icon/MyIcon";

function Login({ onLogin }) {
  // Login - Forgot Password slide animation
  const [toogleSld, setToggleSld] = useState(true);

  const sliderRef = useRef(null);
  const loginRef = useRef(null);
  const forgotRef = useRef(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      gsap.set(sliderRef.current, { clearProps: "x, y, opacity" });
      gsap.set(forgotRef.current, { clearProps: "x, y", opacity: 0 });
      gsap.set(loginRef.current, { clearProps: "x, y", opacity: 1 });
    }
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleLogin = () => {
    setToggleSld(!toogleSld);

    if (windowSize.width > 1024) {
      if (toogleSld) {
        gsap.to(sliderRef.current, {
          duration: 0.6,
          x: "-100%",
          ease: "sine.inOut",
        });
        gsap.to(loginRef.current, {
          duration: 0.6,
          x: "-30px",
          stagger: 0.1,
          opacity: 0,
          ease: "sine.inOut",
        });
        gsap.to(forgotRef.current, {
          duration: 0.6,
          x: 0,
          opacity: 1,
          ease: "sine.inOut",
        });
      } else {
        gsap.to(sliderRef.current, {
          duration: 0.6,
          x: "0",
          ease: "sine.inOut",
        });

        gsap.to(forgotRef.current, {
          duration: 0.6,
          x: "30px",
          opacity: 0,
          ease: "sine.inOut",
        });
        gsap.to(loginRef.current, {
          duration: 0.6,
          x: 0,
          opacity: 1,
          ease: "sine.inOut",
        });
      }
    } else {
      if (toogleSld) {
        gsap.to(sliderRef.current, {
          duration: 0.6,
          y: "-100%",
          ease: "sine.inOut",
        });
        gsap.to(loginRef.current, {
          duration: 0.6,
          y: "-30px",
          stagger: 0.1,
          opacity: 0,
          ease: "sine.inOut",
        });
        gsap.to(forgotRef.current, {
          duration: 0.6,
          y: 0,
          opacity: 1,
          ease: "sine.inOut",
        });
      } else {
        gsap.to(sliderRef.current, {
          duration: 0.6,
          y: "0",
          ease: "sine.inOut",
        });

        gsap.to(forgotRef.current, {
          duration: 0.6,
          y: "30px",
          opacity: 0,
          ease: "sine.inOut",
        });
        gsap.to(loginRef.current, {
          duration: 0.6,
          y: 0,
          opacity: 1,
          ease: "sine.inOut",
        });
      }
    }
  };

  const [loginFailed, setLoginFailed] = useState(false);
  const [passwordFailed, setPasswordFailed] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [loginText, setLoginText] = useState("");
  const [passwordText, setPasswordText] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const newPassword = async (e) => {
    e.preventDefault();
    if (passwordText === "") {
      setPasswordFailed("E-mail alanı zorunludur.");
    } else if (validateEmail(passwordText)) {
      setPasswordFailed("");

      await axios
        .post(process.env.REACT_APP_SERVICE_URL + "/passwordToken", {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
          params: {
            email: passwordText,
          },
        })
        .then((res) => {
          if (res.data) {
            setPasswordFailed("");
            setPasswordSuccess(true);
          } else {
            setPasswordFailed(
              "Girmiş olduğunuz E-mail adresi sistemde bulunamadı."
            );
          }
        })
        .catch(() =>
          setPasswordFailed(
            "İstek sırasında bir hata oluştu,lütfe daha sonra tekrar deneyiniz!"
          )
        );
    } else {
      setPasswordFailed("Lütfen geçerli bir E-mail adresi giriniz.");
    }
  };

  const onSubmit = async (values, actions) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVICE_URL + "/login",
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
          params: {
            email: values.email,
            password: values.password,
          },
        }
      );
      if (response.data) {
        setLoginFailed(false);
        setLoginText("");
        onLogin(response.data);
        actions.resetForm();
      } else {
        setLoginFailed(true);
        setLoginText("Kullanıcı Adınızı veya Şifrenizi Hatalı Girdiniz!");
      }
    } catch (error) {
      setLoginFailed(true);
      setLoginText(
        "İstek sırasında bir hata oluştu,lütfe daha sonra tekrar deneyiniz!"
      );
    }
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit,
    });

  return (
    <div className="loginWrapper">
      <div className="logo">
        <img src={Logo} alt="Total Türkiye" />
      </div>
      <div className="login">
        <div className="loginForm" ref={loginRef}>
          <h2>Login Sayfası</h2>
          <p>
            <strong>Total Türkiye</strong> ödeme sayfasına hoş geldiniz.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="formGroup">
              <label>Kullanıcı Adı</label>
              <input
                id="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                className={errors.email ? "input-error" : ""}
                onBlur={(e) => {
                  if (e.target.value.length > 0) {
                    e.target.classList.add("filled");
                  } else {
                    e.target.classList.remove("filled");
                  }
                }}
              />
              <User />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="formGroup">
              <label>Şifre</label>
              <input
                id="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                className={errors.password ? "input-error" : ""}
                onBlur={(e) => {
                  if (e.target.value.length > 0) {
                    e.target.classList.add("filled");
                  } else {
                    e.target.classList.remove("filled");
                  }
                }}
              />
              <Lock />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>

            <button
              type="submit"
              className="btn btnBlock"
              disabled={isSubmitting}
            >
              Giriş Yap
            </button>
          </form>

          <p className="forgotText">
            Şifrenizi hatırlamıyorsanız
            <span className="link" onClick={toggleLogin}>
              Şifremi Unuttum
            </span>
            yazısına tıklayarak yeni şifre talebinde bulunabilirsiniz.
          </p>

          {loginFailed && <p className="loginFailed">{loginText}</p>}
        </div>
        <div className="forgotForm" ref={forgotRef}>
          <h2>Şifremi Unuttum</h2>
          <p>
            Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize
            ihtiyacımız var.
          </p>
          <form onSubmit={newPassword}>
            <div className="formGroup">
              <label>Kayıtlı E-Posta Adresi:</label>
              <input
                id="resetPass"
                name="resetPass"
                type="email"
                value={passwordText}
                onChange={(e) => {
                  setPasswordText(e.target.value);
                  if (e.target.value.length > 0) {
                    e.target.classList.add("filled");
                  } else {
                    e.target.classList.remove("filled");
                  }
                }}
                className={passwordFailed ? "input-error" : ""}
              />
            </div>

            <button type="submit" className="btn btnBlock">
              Şifre İste
            </button>
          </form>
          <p className="forgotText">
            Kullanıcı girişi yapmak için{" "}
            <span className="link" onClick={toggleLogin}>
              Tıklayınız
            </span>
          </p>
          {passwordFailed && <p className="loginFailed">{passwordFailed}</p>}
          {passwordSuccess && (
            <p className="emailSuccess">
              Şifre sıfırlama linkiniz E-mail adresinize gönderilmiştir.
            </p>
          )}
        </div>
        <div
          ref={sliderRef}
          className={`loginSlider ${toogleSld ? "" : "slideLeft"}`}
        >
          <LoginSlider />
        </div>
      </div>
    </div>
  );
}

export default Login;

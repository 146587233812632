import React from "react";

import { Store } from "../../components/Icon/MyIcon";

import "./header.scss";

function Header() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const ayIsimleri = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const dateString = `${day} ${ayIsimleri[month]} ${year}`;

  const seller = JSON.parse(localStorage.getItem("sellerData"));

  return (
    <header className="header">
      <div className="date">{dateString}</div>
      <div className="user">
        <Store />
        <span>{seller.title}</span>
      </div>
    </header>
  );
}

export default Header;

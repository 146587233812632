import React from 'react'
import './navbar.scss'

function Navbar({ children, title  }) {
  return (
    <div className='navbar'>
        {title && <h2>{title}</h2>}
        <div className="navbarContent">
            { children }
        </div>
    </div>
  )
}

export default Navbar
import React from 'react'
import { NavLink } from "react-router-dom"
import Total from "../../assets/img/total-primary.svg"

import { Home, CreditCard, LogOut, CheckList } from '../Icon/MyIcon'

import './menu.scss'

function Menu({ logOut }) {

  return (
    <div className='menuWrapper'>
      <div className="logo">
        <img src={ Total } alt="Total Türkiye" />
      </div>
      <nav className='menu'>
          <ul>
            <li>
              <NavLink 
                to="/"
              >
                <Home />
                <span>Ana Sayfa</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/orders"
              >
                <CreditCard />
                <span>Ödeme Yap</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/list"
              >
                <CheckList />
                <span>İşlemlerim</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/logout" onClick={ logOut }>
                <LogOut />
                <span>Çıkış Yap</span>
              </NavLink>
            </li>
          </ul>
      </nav>
    </div>
  )
}

export default Menu
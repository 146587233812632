import React, { useEffect, useState } from "react";
import { Autoplay, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./login-slider.scss";

function LoginSlider() {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    LoginSlider();
  }, []);

  async function LoginSlider() {
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/loginSlider", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => setSliderData(res.data))
      .catch(() => console.error("Slider verisi alınamadı"));
  }

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '" key="' + index + '"></span>';
    },
  };

  return (
    <>
      <Swiper
        modules={[Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={pagination}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {sliderData.length > 0
          ? sliderData.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <img
                    src={
                      process.env.REACT_APP_UPLOADS_PATH +
                      "/slider/" +
                      item.image
                    }
                    alt=""
                  />
                </SwiperSlide>
              );
            })
          : ""}
        <div id="containerForBullets"></div>
      </Swiper>
      <div id="containerForBullets"></div>
    </>
  );
}

export default LoginSlider;

import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CardForm from "../../components/CardForm/CardForm";
import Navbar from "../../components/Navbar/Navbar";
import axios from "axios";

function Orders() {
  const [bankTest, setBankTest] = useState("");
  useEffect(() => {
    getBankInfo();
  }, []);

  async function getBankInfo() {
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/payment_info", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => setBankTest(res.data))
      .catch(() => console.error("Bank taksit verisi alınamadı"));
  }

  return (
    <div className="pages activeNavBar">
      <div className="orders">
        <Header />
        <div className="content">
          <CardForm />
        </div>
      </div>
      <Navbar title="Kart Bilgileri">
        <div dangerouslySetInnerHTML={{ __html: bankTest.content }}></div>
        <div dangerouslySetInnerHTML={{ __html: bankTest.content2 }}></div>
      </Navbar>
    </div>
  );
}

export default Orders;

import React from "react";

import "./bank-installment.scss";

function BankInstallment({ status, clickInstallment, installmentData }) {
  return (
    <div className={`installment ${status ? "active" : ""}`}>
      <div className="head">
        <img src={installmentData.resim} alt="Banka" />
      </div>
      <div className="body">
        <div className="row title">
          <div>Taksit Tutarı</div>
          <div>Aylık Ödeme</div>
        </div>
        {installmentData.taksitler?.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="instalmentNumber">
                <input
                  type="radio"
                  name="taksit"
                  id={"id" + item.key}
                  value={item.key}
                  onChange={() => {
                    clickInstallment(item.key);
                  }}
                  defaultChecked={item.key === 1}
                />
                <label htmlFor={"id" + item.key}>
                  <span>
                    {item.taksit} {item.key > 1 && "Taksit"}
                  </span>
                  {item.arti && <span className="bonus">{item.arti}</span>}
                </label>
              </div>
              <div className="totalPayment">{item.taksitHesap}</div>
            </div>
          );
        })}
      </div>
      {installmentData.text ? (
        <div className="noInstallement">{installmentData.text}</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default BankInstallment;

import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Print } from "../Icon/MyIcon";

import "./printReceipt.scss";

function PrintReceipt({ orderData }) {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  return (
    <>
      <button
        className="printBtn"
        onClick={() => {
          handlePrint(null, () => contentToPrint.current);
        }}
      >
        <Print />
      </button>
      <div className="printWrapper">
        <div className="printArea" ref={contentToPrint}>
          <div className="section">
            <h1>Kredi Kartı Ödeme Talimatı</h1>
            <div className="date">
              <div className="date">{orderData.order_date}</div>
            </div>
            <ul>
              <li>
                <strong>İşlem No:</strong> <span>{orderData.reference_no}</span>
              </li>
              <li>
                <strong>Üye İşyeri:</strong>{" "}
                <span>Özaylar El Aletleri A.Ş.</span>
              </li>
              <li>
                <strong>Adres:</strong>{" "}
                <span>Şehit Teğmen Kalmaz Cad. (Posta Cad.) No:21 </span>
              </li>
            </ul>
          </div>

          <div className="section">
            <p>
              <strong>1) Alıcı / Müşteri Adı ve Soyadı:</strong>{" "}
              {orderData.pay_name}
            </p>
            <ul>
              <li>
                <strong>Banka:</strong> <span>{orderData.bank_name}</span>
              </li>
              <li>
                <strong>Provizyon Tutarı:</strong>{" "}
                <span>{orderData.amount} TL</span>
              </li>
              <li>
                <strong>Taksit Sayısı:</strong>{" "}
                <span>
                  {orderData.installment < 2
                    ? "Tek Çekim"
                    : orderData.installment}
                </span>
              </li>
            </ul>
            <p>
              İlave taksit kampanyaları bankalar tarafından uygulanmakta olup
              sanal pos ekranında ve ödeme dekontlarında bilgi olarak
              gösterilemektedir. Aidatlı olmayan kredi kartlarına + taksit
              uygulanmaz.
            </p>
            <p>
              Bilgilerini vermiş olduğum kredi kartımdan{" "}
              <strong>{orderData.amount} TL</strong>. tutarın çekilmesini ve
              çekilen bu tutarı Özaylar El Aletleri Hır. İns. Tic. Ve San. A.Ş.
              ‘ne ödemiş bulunduğumu, ilgili firma ile aramda oluşabilecek
              anlaşmazlıklardan dolayı Özaylar El Aletleri Hır. İns. Tic. Ve
              San. A.Ş.’yi sorumlu tutmayacağımı beyan ve taahhüt ederim.
            </p>

            <p>
              <strong>Alıcı / Müşteri Adı Soyadı ve İmzası:</strong>
            </p>
          </div>

          <div className="mt-150 section">
            <p>
              <strong>2)</strong> Yukarıda bilgileri verilen kredi kartından
              çekilen <strong>{orderData.amount} TL</strong> tutarın bayisi
              bulunduğumuz Özaylar El Aletleri Hır. İns. Tic. Ve San. A.Ş. ’nin
              nezdindeki cari hesabımıza alacak kaydedilmesini, çekilen tutara
              kredi kartı sahibinin isteği doğrultusunda banka tarafından bloke
              konur ve Bosch Sanayi ve Ticaret A.Ş.’nin hesaplarına aktarılmaz
              ise ilgili tutarı her türlü gecikme faizleri ile birlikte Özaylar
              El Aletleri Hır. İns. Tic. Ve San. A.Ş.’nin talep ettiği tarihte
              nakit olarak Özaylar El Aletleri Hır. İns. Tic. Ve San. A.Ş. ’ye
              ödeyeceğimizi beyan ve taahhüt ederiz.
            </p>

            <p>
              <strong>Satıcı / Bayi Unvanı, kaşe ve İmzası:</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintReceipt;

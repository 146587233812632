export const getSeverity = (status) => {
  switch (status) {
    case "Başarılı":
      return "success";

    case "Başarısız":
      return "warning";

    case "İade Edildi":
      return "danger";

    case "negotiation":
      return "info";

    default:
      return null;
  }
};

export const moneyShow = (money, currency = " TL") => {
  const formattedNumber = new Intl.NumberFormat("tr-TR", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(money);

  return `${formattedNumber} ${currency}`;
};

import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom";
import { moneyShow } from "../../config/Helper";

import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import { Cog, Reload, Print } from "../../components/Icon/MyIcon";
import { getSeverity } from "../../config/Helper";
import axios from "axios";

import "./list.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

function List() {
  useEffect(() => {
    getOrderList();
  }, []);

  const [orders, setOrders] = useState([]);
  const getOrderList = async () => {
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/getOrderList", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
        params: {
          user: JSON.parse(localStorage.getItem("sellerData")).id,
        },
      })
      .then((res) => setOrders(res.data))
      .catch(() => console.error("Orders verisi alınamadı"));
  };

  const [orderInfo, setOrderInfo] = useState("");

  const selectOrder = (order) => {
    setOrderInfo(order);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Link
          className="detail mr5"
          to={`/receipt?q=${btoa(rowData.id + "#" + rowData.user_id)}`}
        >
          <Print />
        </Link>
        <button className="detail" onClick={() => selectOrder(rowData)}>
          <Cog />
        </button>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.order_status}
        severity={getSeverity(rowData.order_status)}
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    if (rowData.refund_id) {
      return (
        <>
          <span className="textBold">{moneyShow(rowData.amount)}</span>
          <br />
          <span className="textRed">İade Talebi Alındı</span>
        </>
      );
    } else {
      return <span className="textBold">{moneyShow(rowData.amount)}</span>;
    }
  };

  const orderDetail = useRef(null);
  const reloadAnimation = () => {
    if (orderInfo) {
      orderDetail.current.classList.add("is-animating");

      setTimeout(() => {
        orderDetail.current.classList.remove("is-animating");
      }, 1000);
    }
  };

  useEffect(() => {
    if (orderInfo !== null) {
      reloadAnimation();
      setRefundRequest(false);
    }
  }, [orderInfo]);

  const [refundRequest, setRefundRequest] = useState(false);

  const iadeTalebi = async () => {
    reloadAnimation();
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/refund", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
        orderInfo,
      })
      .then((res) => {
        if (res.data === true) {
          orderInfo.refund_id = 1;
          setRefundRequest(true);
          getOrderList();
        } else {
          console.log("no");
        }
      })
      .catch(() => console.error("Refund verisi alınamadı"));
  };

  return (
    <div className="pages activeNavBar">
      <div className="list">
        <Header />
        <div className="content">
          <DataTable value={orders} tableStyle={{ minWidth: "50rem" }}>
            <Column
              mn
              sortable
              field="order_status"
              header="Durum"
              body={statusBodyTemplate}
            ></Column>
            <Column field="order_date" sortable header="Tarih"></Column>
            <Column field="pay_name" header="Kart Sahibi"></Column>
            <Column
              field="amount"
              header="Tutar"
              body={priceBodyTemplate}
            ></Column>
            <Column field="bank_name" header="Banka"></Column>
            <Column field="installment" header="Taksit"></Column>
            <Column
              headerStyle={{ width: "7rem", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
      <Navbar title="Kart Bilgileri">
        {orderInfo ? (
          <div className="orderDetail" ref={orderDetail}>
            <div className="orderDetailInfo">
              <button
                className="btn btnSm"
                onClick={() => {
                  setOrderInfo("");
                  setRefundRequest(false);
                }}
              >
                Kapat
              </button>
              <p>
                <span>Adı Soyadı</span>
                <span>: {orderInfo.pay_name}</span>
              </p>
              <p>
                <span>Telefon</span>
                <span>: {orderInfo.phone}</span>
              </p>
              <p>
                <span>Mail</span>
                <span>: {orderInfo.email}</span>
              </p>
              <p>
                <span>Banka</span>
                <span>: {orderInfo.bank_name}</span>
              </p>
              <p>
                <span>Tutar</span>
                <span>: {moneyShow(orderInfo.amount)}</span>
              </p>
              <p>
                <span>Taksit</span>
                <span>: {orderInfo.installment}</span>
              </p>
              <p>
                <span>Ödeme Durumu</span>
                <span>: {orderInfo.order_status}</span>
              </p>
              {orderInfo.order_status === "Başarılı" &&
                !orderInfo.refund_id && (
                  <>
                    <button className="btn btnBlock" onClick={iadeTalebi}>
                      İade Talebi Oluştur
                    </button>
                    <small>
                      Not: İade talebiniz onaylandıktan sonra banka tarafına
                      düşmesi 4-5 iş gününü bulmaktadır.
                    </small>
                  </>
                )}
              {refundRequest && (
                <div className="refundText">İade Talebiniz Alınmıştır</div>
              )}
            </div>

            <div className="reload">
              <Reload />
            </div>
          </div>
        ) : (
          <div>
            <p>Detayını görmek istediğiniz siparişi seçiniz.</p>
          </div>
        )}
      </Navbar>
    </div>
  );
}

export default List;

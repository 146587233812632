import React from 'react'

import { Outlet } from "react-router-dom";

import Menu from '../components/Menu/Menu';

function Template({ logOut }) {
  return (
    <>
      <Menu logOut={ logOut } />
      <main className='mainContent'>
        <Outlet />
      </main>
    </>
  )
}

export default Template
import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";
import CurrencyInput from "../FormHelper/CurrencyInput";

import {
  Money,
  User,
  CreditCard,
  Calendar,
  CardCvc,
  Mail,
  Phone,
} from "../../components/Icon/MyIcon";

import Chip from "../../assets/img/chip.svg";
import BankInstallment from "../BankInstallment/BankInstallment";

import "./card-form.scss";
import axios from "axios";

function CardForm() {
  // Installment Select
  const [installment, setInstallment] = useState(1);
  const selectInstallment = (i) => {
    setInstallment(i);
  };

  // Amount Control
  const [amount, setAmount] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const amountKeyup = (val) => {
    setAmount(val);
  };

  //Card number animation
  const cardNumber = useRef(null);
  const inputNumber = useRef(null);
  const card = useRef(null);

  const cardNumberAnime = (e) => {
    const inputText = e.target.value.replace(/[ _]/g, "");
    const inputTextArray = inputText.split("");
    const inputLength = inputTextArray.length;
    const cardOrder = cardNumber.current.querySelector(
      "span:nth-child(" + inputLength + ")"
    );

    if (inputLength > 0) {
      cardOrder.classList.add("slide-fade");

      setTimeout(() => {
        cardOrder.innerHTML = inputTextArray[inputLength - 1];
        cardOrder.classList.remove("slide-fade");
        cardOrder.classList.add("slide-show");
      }, 100);

      setTimeout(() => {
        cardOrder.classList.remove("slide-show");
      }, 500);
    }

    for (var i = inputLength + 1; i <= 16; i++) {
      cardNumber.current.querySelector(
        "span:nth-child(" + i + ")"
      ).innerHTML = 0;
    }

    CreditCardControl(inputText);
  };

  //Card name innerHtml
  const [cardNames, setCardNames] = useState("Ad Soyad");
  const cardNameAnime = (e) => {
    if (e.target.value.length === 0) {
      setCardNames("Ad Soyad");
    } else {
      setCardNames(e.target.value);
    }
  };

  //Card date innerHtml
  const [cardDate, setCardDate] = useState("../..");
  const cardDateAnime = (e) => {
    if (e.target.value.length === 0) {
      setCardDate("../..");
    } else {
      setCardDate(e.target.value);
    }
  };

  const [installmentStatus, setInstallmentStatus] = useState(false);
  const [sendCard, setSendCard] = useState("");
  const CreditCardControl = (cardNo) => {
    if (cardNo.length === 16) {
      getApiBinList(cardNo);
      setSendCard(cardNo);
      setSendAmount(amount);
    } else {
      setInstallmentStatus(false);
    }
  };

  const [apiInstallment, setApiInstallment] = useState([]);
  const [selectBank, setSelectBank] = useState(0);
  const getApiBinList = async (cardNo) => {
    await axios
      .post("https://www.totalturkiye.com.tr/api/getBinList", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
        params: {
          card: cardNo,
          amount: amount,
        },
      })
      .then((res) => {
        setApiInstallment(res.data);
        setSelectBank(res.data.Banka);
      })
      .catch(() => console.error("Bin verisi alınamadı"))
      .finally(() => {
        setInstallmentStatus(true);
      });
  };

  //Card turn animation and ccv innerHtml
  const ccv = useRef(null);
  const cardTurnBack = () => {
    card.current.style.transform = "rotateY(180deg)";
    setTimeout(() => {
      card.current.classList.add("back");
    }, 150);
  };
  const cardTurnFront = () => {
    card.current.style.transform = "rotateY(0deg)";
    setTimeout(() => {
      card.current.classList.remove("back");
    }, 150);
  };

  const [cardCcv, setCardCcv] = useState("");
  const cardCcvAnime = (e) => {
    const inputCcv = e.target.value.replace(/[ _]/g, "");
    setCardCcv(inputCcv);
  };

  const updateAmount = () => {
    if (sendAmount !== amount && sendCard.length === 16) {
      getApiBinList(sendCard);
      setSendAmount(amount);
    }
  };

  return (
    <div className="payPage">
      <div className="paymentInfo">
        <div className="card-wrapper">
          <div className="card" ref={card}>
            <div className="highlight"></div>

            <div className="masterIcon">
              <span></span>
              <span></span>
            </div>

            <div className="chip">
              <img src={Chip} alt="credit-card-chip" />
            </div>
            <div className="number" ref={cardNumber}>
              <span>0</span>
              <span>0</span>
              <span>0</span>
              <span>0</span>

              <span>0</span>
              <span>0</span>
              <span>0</span>
              <span>0</span>

              <span>0</span>
              <span>0</span>
              <span>0</span>
              <span>0</span>

              <span>0</span>
              <span>0</span>
              <span>0</span>
              <span>0</span>
            </div>
            <div className="user">
              <div className="name">
                <small>Kart Sahibi</small>
                <span>{cardNames}</span>
              </div>
              <div className="date">
                <small>Tarih</small>
                <span>{cardDate}</span>
              </div>
            </div>

            <div className="bant"></div>
            <div className="ccv">{cardCcv}</div>
          </div>
        </div>
        <BankInstallment
          status={installmentStatus}
          clickInstallment={selectInstallment}
          installmentData={apiInstallment}
        />
      </div>

      <div className="form">
        <form
          action={process.env.REACT_APP_SERVICE_URL + "/payment"}
          method="post"
        >
          <input
            type="hidden"
            name="token"
            value={process.env.REACT_APP_TOKEN}
          />
          <input
            type="hidden"
            name="user"
            value={JSON.parse(localStorage.getItem("sellerData")).id}
          />
          <input type="hidden" name="installment" value={installment} />
          <input type="hidden" name="bankSelect" value={selectBank} />
          <div className="formGroup">
            <label>Tutar</label>
            <CurrencyInput
              name="amount"
              type="text"
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  e.target.classList.add("filled");
                } else {
                  e.target.classList.remove("filled");
                }
                amountKeyup(e.target.value);
              }}
              onBlur={updateAmount}
              required
            />
            <Money />
          </div>

          <h3>KART BİLGİLERİ</h3>
          <div className="formGroup">
            <label>Adı Soyadı</label>
            <input
              type="text"
              name="cardname"
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  e.target.classList.add("filled");
                } else {
                  e.target.classList.remove("filled");
                }
              }}
              onKeyUp={cardNameAnime}
              required
            />
            <User />
          </div>
          <div className="formGroup">
            <label>Kart Numarası</label>
            <MaskedInput
              required
              type="text"
              name="card_number"
              disabled={!amount}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  e.target.classList.add("filled");
                } else {
                  e.target.classList.remove("filled");
                }
                cardNumberAnime(e);
              }}
              ref={inputNumber}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
            <CreditCard />
          </div>
          <div className="group">
            <div className="formGroup">
              <label>Tarih</label>
              <InputMask
                type="text"
                name="card_date"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    e.target.classList.add("filled");
                  } else {
                    e.target.classList.remove("filled");
                  }
                }}
                onKeyUp={cardDateAnime}
                mask="99/99"
                required
              />
              <Calendar />
            </div>
            <div className="formGroup">
              <label>CCV</label>
              <InputMask
                type="text"
                name="card_ccv"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    e.target.classList.add("filled");
                  } else {
                    e.target.classList.remove("filled");
                  }
                  cardCcvAnime(e);
                }}
                ref={ccv}
                onBlur={cardTurnFront}
                onClick={cardTurnBack}
                mask="999"
                required
              />
              <CardCvc />
            </div>
          </div>

          <h3>KİŞİ BİLGİLERİ</h3>
          <div className="formGroup">
            <label>Telefon</label>
            <InputMask
              type="text"
              name="phone"
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  e.target.classList.add("filled");
                } else {
                  e.target.classList.remove("filled");
                }
              }}
              mask="0(999) 999 99 99"
              required
            />
            <Phone />
          </div>
          <div className="formGroup">
            <label>E-Posta</label>
            <input
              type="email"
              name="email"
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  e.target.classList.add("filled");
                } else {
                  e.target.classList.remove("filled");
                }
              }}
            />
            <Mail />
          </div>
          <div className="formGroup">
            <button className="btn btnBlock" type="submit">
              Ödemeyi Tamamla
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CardForm;

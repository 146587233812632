import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import PrintReceipt from "../../components/PrintReceipt/PrintReceipt";
import PrintReceipt2 from "../../components/PrintReceipt/PrintReceipt2";

function ReceiptPage() {
  const [params] = useSearchParams();
  const [order, setOrder] = useState([]);

  const getParams = async () => {
    try {
      let [orderID, userID] = atob(params.get("q")).split("#");
      await axios
        .post(process.env.REACT_APP_SERVICE_URL + "/getOrder", {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
          params: {
            user: userID,
            order: orderID,
          },
        })
        .then((res) => setOrder(res.data))
        .catch(() => console.error("Order verisi alınamadı"));
    } catch (error) {
      window.location.assign("/list");
    }
  };

  getParams();

  return (
    <div className="paymentOrder">
      {order.bank_name === "İş Bankası" ? (
        <PrintReceipt2 orderData={order} />
      ) : (
        <PrintReceipt orderData={order} />
      )}
    </div>
  );
}

export default ReceiptPage;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";
import axios from "axios";
import { NavLink } from "react-router-dom";

import Header from "../../components/Header/Header";
import "./home.scss";
import "swiper/css";

import isBank from "../../assets/img/isbank.svg";
import paramPos from "../../assets/img/parampos.svg";

function Home() {
  const [sliderData, setSliderData] = useState([]);
  const [bankData, setBankData] = useState([]);

  useEffect(() => {
    LoginSlider();
    banks();
  }, []);

  async function LoginSlider() {
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/loginSlider/2", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => setSliderData(res.data))
      .catch(() => console.error("Slider verisi alınamadı"));
  }
  async function banks() {
    await axios
      .post(process.env.REACT_APP_SERVICE_URL + "/banks", {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => setBankData(res.data))
      .catch(() => console.error("Banka verisi alınamadı"));
  }

  return (
    <div className="pages">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Total Türkiye Ödeme Portalı</title>
        <meta name="robots" content="noindex"></meta>
        <meta
          name="description"
          content="Total Türkiye ödeme sayfası portalı"
        />
      </Helmet>
      <div className="home">
        <Header />
        <div className="content">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            //onSlideChange={() => console.log("slide change")}
            //onSwiper={(swiper) => console.log(swiper)}
            className="mainSlider"
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {sliderData.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <img
                    src={
                      process.env.REACT_APP_UPLOADS_PATH +
                      "/slider/" +
                      item.image
                    }
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="row g30">
            <div className="col-6">
              <div className="card bankCard">
                <img src={isBank} alt="payment" />
                <h2>Tek Çekim ve İş Bankası kartlar için <NavLink to="/orders">Tıklayınız.</NavLink></h2>
                <p>Sadece tek çekim işlemler ve İş bankası taksitlerinde kullanabilirsiniz.</p>
                <NavLink to="/orders">
                  <button className="btn btnBlock">İş Bankası</button>
                </NavLink>
              </div>
            </div>
            <div className="col-6">
              <div className="card bankCard">
                <img src={paramPos} alt="payment" />
                <h2>Taksitli diğer bankalar için <NavLink to="https://pos.param.com.tr/Tahsilat/Default-v2.aspx?k=e2939b15-5078-4ca0-a57f-ef1fefb238fc" target="_blank">Tıklayınız.</NavLink></h2>
                <p><strong>Not:</strong> Açıklama bölümüne <strong className="alertColor">Firma İsmi</strong> yazmayı unutmayınız.</p>
                <NavLink to="https://pos.param.com.tr/Tahsilat/Default-v2.aspx?k=e2939b15-5078-4ca0-a57f-ef1fefb238fc" target="_blank">
                  <button className="btn btnBlock">Diğer Bankalar</button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="row mt-80">
            <div className="col-12">
              <div className="banks">
                <div className="title">
                  Özaylar El Aletleri Hırd. İnş. Tic. ve San. A.Ş.
                </div>

                {bankData.map((item, key) => {
                  return (
                    <div className="bank" key={key}>
                      <div className="logo">
                        <img
                          src={
                            process.env.REACT_APP_UPLOADS_PATH +
                            "/bank/" +
                            item.image
                          }
                          height={30}
                          alt={item.title}
                        />
                      </div>
                      <div className="info">
                        <ul>
                          <li>
                            <span>Hesap No</span>{" "}
                            <span>{item.account_number}</span>
                          </li>
                          <li>
                            <span>Şube Adı/Kodu</span>{" "}
                            <span>
                              {item.branch_name} / {item.branch_no}
                            </span>
                          </li>
                          <li>
                            <span>IBAN</span> <span>{item.iban}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React from "react";

import "./result.scss";

function SuccessPage() {
  return (
    <div className="centerPage">
      <div className="card">
        <div className="cardHeader success">
          <div className="ui-success">
            <svg
              viewBox="0 0 87 87"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group-3" transform="translate(2.000000, 2.000000)">
                  <circle
                    id="Oval-2"
                    stroke="rgba(255, 255, 255, 0.2)"
                    strokeWidth="4"
                    cx="41.5"
                    cy="41.5"
                    r="41.5"
                  ></circle>
                  <circle
                    className="ui-success-circle"
                    id="Oval-2"
                    stroke="#fff"
                    strokeWidth="4"
                    cx="41.5"
                    cy="41.5"
                    r="41.5"
                  ></circle>
                  <polyline
                    className="ui-success-path"
                    id="Path-2"
                    stroke="#fff"
                    strokeWidth="4"
                    points="19 38.8036813 31.1020744 54.8046875 63.299221 28"
                  ></polyline>
                </g>
              </g>
            </svg>
          </div>
          <h1>TEBRİKLER !</h1>
        </div>
        <div className="cardBody">
          <p>
            Ödeme işleminiz başarı ile alınmıştır. Ödeme işlemini kayıt altına
            alabilmeniz için lütfen ödeme talimatını müşetirinize imzalatınız ve
            ıslak imzalı kopyasını saklayınız.
          </p>

          <a href="/list" className="btn btnSucccess">
            İşlemlerime Git
          </a>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;

import * as yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const basicSchema = yup.object().shape({
  email: yup
    .string()
    .email("Geçerli bir email giriniz")
    .required("Email girmek zorunludur"),
  age: yup
    .number()
    .positive("Lütfen geçerli bir yaş giriniz")
    .integer("Lütfen yaşınızı tam sayı olarak giriniz")
    .required("Yaş girmek zorunludur"),
  password: yup
    .string()
    .min(5, "En az 5 karakter olmalı")
    .matches(passwordRules, {
      message: "Lütfen en az 1 büyük harf, 1 küçük harf ve sayı giriniz",
    })
    .required("Şifre girmek zorunludur"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Şifrele eşleşmiyor")
    .required("Şifre tekrarı zorunludur"),
});

export const advancedSchema = yup.object().shape({
  username: yup
    .string()
    .min(3, "En az 3 karakter olmalı")
    .required("Kullanıcı adı zorunludur"),
  university: yup
    .string()
    .oneOf(
      ["bogazici", "gsu", "odtu", "itu"],
      "Lütfen üniversitesinizi seçiniz."
    )
    .required("Üniversite alanı zorunludur"),
  isAccepted: yup.boolean().oneOf([true], "Kullanım koşullarını kabul ediniz"),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Lütfen geçerli bir email adresi giriniz!")
    .required("Email alanı zorunludur!"),
  password: yup.string().required("Şifre alanı zorunludur!"),
});

export const passwordSchema = yup.object().shape({
  email2: yup
    .string()
    .email("Lütfen geçerli bir email adresi giriniz!")
    .required("Email alanı zorunludur!"),
});
